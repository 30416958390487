/**
 * @Author: Kerem Koç <keremkoc>
 * @Date:   2018-01-25T02:49:05+03:00
 * @Email:  kerem@kocapps.com
 * @Filename: css.js
 * @Last modified by:   keremkoc
 * @Last modified time: 2018-02-26T21:18:02+03:00
 */


import './font/fonts.css';
import './sass/style.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import './vendor/fontawesome-free/svg-with-js/css/fa-svg-with-js.css';
import '../node_modules/animate.css/animate.min.css';
import '../node_modules/owl.carousel/dist/assets/owl.carousel.min.css';
//
//
import './css/owl.theme.css';
import './css/slick.css';
import './css/slick-theme.css';
import './css/owl.transitions.css';
import './css/jquery.fancybox.css';
import './css/jquery.mCustomScrollbar.min.css';
//
// import '../node_modules/@fortawesome/fontawesome-pro/css/solid.css';
//








// import './less/_main.less';
// import './css/font-awesome.min.css';
// import './css/flaticon.css';
// import './css/owl.carousel.css';


/*
<link href="assets/css/font-awesome.min.css" rel="stylesheet">
<link href="assets/css/flaticon.css" rel="stylesheet">

<!-- Bootstrap core CSS -->
<link href="assets/css/bootstrap.min.css" rel="stylesheet">

<!-- Plugins for this template -->
<link href="assets/css/animate.css" rel="stylesheet">
<link href="assets/css/owl.carousel.css" rel="stylesheet">
<link href="assets/css/owl.theme.css" rel="stylesheet">
<link href="assets/css/slick.css" rel="stylesheet">
<link href="assets/css/slick-theme.css" rel="stylesheet">
<link href="assets/css/owl.transitions.css" rel="stylesheet">
<link href="assets/css/jquery.fancybox.css" rel="stylesheet">
<link href="assets/css/jquery.mCustomScrollbar.min.css" rel="stylesheet">
*/
